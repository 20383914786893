import React from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Image from 'reusecore/src/elements/Image';
import Heading from 'reusecore/src/elements/Heading';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import BannerWrapper, {
  BannerObject,
  ExternalLink
} from './bannerSection.style';

import ExperimentsView from 'common/src/assets/image/saas/nba-experiments.png';
import Experiment1 from 'common/src/assets/image/saas/nba-experiment1.png';
import SimulationView from 'common/src/assets/image/saas/nba-simulation.png';

const NBASection = ({
  row,
  col,
  title,
  description,
}) => {
  return (
    <BannerWrapper id="banner_section">
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <FeatureBlock
              title={
                <Heading
                  content="NBA Platform"
                  {...title}
                />
              }
              description={
                <div>
                  <Text
                    content="The Next Best Action Platform consolidates multiple systems from Pega, Unica, and others to provide a strategic capability, running experiments at scale, using 360 views of customers and households, to achieve one-to-one personalisation."
                    {...description}
                  />
                  <Text/>
                  <Image src={Experiment1} alt="Create Experiment" />
                  <p></p>
                  <Image src={SimulationView} alt="Run Simulation" />
                </div>
              }
            />
          </Box>
        </Box>
      </Container>
      <BannerObject style={{ alignItems: 'flex-start', top: 157}}>
        <div className="objectWrapper">
          <Image src={ExperimentsView} alt="NBA Experiments" />
        </div>
      </BannerObject>
    </BannerWrapper>
  );
};

NBASection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
};

NBASection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, '70%', '50%', '45%'],
  },
  title: {
    fontSize: ['22px', '34px', '30px', '40px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    lineHeight: '1.5',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '0',
  },
};

export default NBASection;
